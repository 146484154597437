@import '__settings/init';

@keyframes cursorRotateText {
	0% {
		transform: rotate(360deg);
	}
	100% {
		transform: rotate(0deg);
	}
}

.cursor {
	pointer-events: none;

	width: rem(40);
	height: rem(40);

	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;

	border-radius: 50%;
	cursor: default;
	opacity: 0;

	transition: opacity $default-transition-speed ease-in-out, width $default-transition-speed ease-in-out, height $default-transition-speed ease-in-out, color $default-transition-speed;

	svg {
		width: 100%;
		height: 100%;

		position: absolute;
		top: 0;
		left: 0;

		circle {
			stroke: $color--dark;
			transition: stroke $default-transition-speed ease-in-out;
		}
	}

	&--light {
		color: $color--light;

		svg circle {
			stroke: #b3b3b3;
		}

		.cursor-play-movie,
		.cursor-talk-to-me,
		.cursor-explore-project,
		.cursor-sound-wave,
		.cursor-explore,
		.cursor-more {
			background-color: rgba(0,0,0,.5);
		}
	}

	&--dark {
		color: $color--dark;

		.cursor-play-movie,
		.cursor-talk-to-me,
		.cursor-explore-project,
		.cursor-sound-wave,
		.cursor-explore,
		.cursor-more {
			background-color: rgba(255,255,255,.5);
		}
	}

	&.active {
		opacity: 1;
	}

	&.active-disabled {
		opacity: 0 !important;
	}

	&.cursor-expand {
		width: rem(84);
		height: rem(84);
	}

	&.cursor-video,
	&.cursor-talk,
	&.cursor-explore-project,
	&.cursor-sound,
	&.cursor-explore,
	&.cursor-more {
		width: rem(110);
		height: rem(110);

		svg circle {
			stroke: transparent;
		}

		.cursor__sub {
			transition: opacity .8s ease-in-out;
		}
	}

	&.cursor-hide {
		opacity: 0;
		width: rem(0);
		height: rem(0);
	}

	&.cursor-video .cursor-play-movie {
		opacity: 1;
	}

	&.cursor-talk .cursor-talk-to-me {
		opacity: 1;
	}

	&.cursor-explore .cursor-explore {
		opacity: 1;
	}

	&.cursor-sound .cursor-sound-wave {
		opacity: 1;
	}

	&.cursor-explore-project .cursor-explore-project {
		opacity: 1;
	}

	&.cursor-more .cursor-more {
		opacity: 1;
	}

	&.cursor-text-circle {
		width: rem(120);
		height: rem(120);

		svg circle {
			stroke: transparent;
		}

		.cursor-circle-text {
			opacity: 1;
		}

		.cursor__sub {
			transition: opacity .8s ease-in-out;
			transition-delay: .3s;
		}
	}

	&__sub {
		display: flex;
		align-items: center;
		justify-content: center;

		width: 100%;
		height: 100%;

		position: absolute;
		top: 0;
		left: 0;

		border-radius: 50%;
		opacity: 0;

		transition: opacity 0s ease-in-out;
	}

	.cursor-play-movie,
	.cursor-talk-to-me,
	.cursor-explore-project,
	.cursor-sound-wave,
	.cursor-explore,
	.cursor-more {
		font-size: rem(16);
		line-height: 1;
		text-decoration: underline;

		@include noe-display-medium;
		-webkit-backdrop-filter: blur(10px);
		backdrop-filter: blur(10px);

		&__cta {
			flex-shrink: 0;
		}
	}

	.cursor-circle-text {
		&__center-text {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -40%);

			font-size: rem(60);
			line-height: rem(60);

			@include noe-display-thin;
		}

		&__edge-text {
			width: 100%;
			height: 100%;

			position: absolute;

			font-size: rem(9);
			line-height: rem(9);
			text-transform: uppercase;

			animation: cursorRotateText 10s linear infinite;

			@include theinhardt-thin;

			span {
				position: absolute;
				left: 50%;
				transform-origin: 0 60px;

				@include theinhardt-thin;
			}

			strong {
				@include theinhardt-regular;
			}
		}
	}

	&-more {
		&__cta {
			display: flex;
			align-items: center;
			gap: rem(10);
		}

		&__arrow {
			width: rem(10);
			height: rem(10);

			display: flex;

			svg {
				width: rem(10);
				height: rem(10);
				margin-top: rem(2);

				position: relative;
			}
		}

	}
}

.plat-macos, .plat-ios {
	.cursor {
		.cursor-circle-text {
			&__center-text {
				line-height: 0;
			}
		}
	}
}
