@import '__settings/init';

.intro {
    padding: rem(40) 0;
    position: relative;

    &__caption {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: rem(20);
        padding-top: rem(12);
        opacity: 0.4;
        margin-bottom: rem(41);
        position: relative;

        &::before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            width: 0;
            height: rem(1);
            background-color: currentColor;
            transition: all 1s ease-in-out;
        }

        @include media('>=md') {
            align-items: baseline;
            justify-content: flex-start;
            gap: rem(187);
            padding-top: rem(15);
            margin-bottom: rem(88);
        }

        &.active {
            opacity: 0.4;

            &::before {
                width: 100%;
            }
        }

        &-icon {
            display: flex;

            svg {
                width: rem(10);
                height: rem(10);

                @include media('>=md') {
                    width: rem(15);
                    height: rem(15);
                }

                path {
                    fill: currentColor;
                }
            }
        }

        &-content {
            @include font(12, 13);
        }
    }

    &__content {
        margin: 0 auto;
        max-width: rem(718);
    }

    &__row {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: rem(35);

        @include media('>=md') {
            flex-direction: row;
            gap: rem(84);
        }
    }

    &__col {
        flex-shrink: unset;
        padding: 0;
        width: 100%;

        @include media('>=md') {
            width: 50%;
        }

        &--desc {
            @include media('>=md') {
                max-width: rem(254);
                margin-top: rem(106);
            }
            @include media('>=lg') {
            }
            p {
                margin-top: 0;

                &:not(:last-of-type) {
                    margin-bottom: rem(8);

                    @include media('>=md') {
                        margin-bottom: rem(10);
                    }
                }

                &:last-of-type {
                    margin-bottom: rem(35);

                    @include media('>=md') {
                        margin-bottom: rem(33);
                    }
                }
            }

            h1, h2, h3, h4, h5, h6 {
                @include font(18, 27);
                @include noe-display-thin;

                margin: 0;

                @include media('>=md') {
                    @include font(22, 35);
                }
            }
        }
    }

    &__title {
        @include noe-display-thin;
        @include font(25, 32);

        @include media('>=md') {
            @include font(35, 45);
        }

        p {
            margin: 0;

            @include media('<=md') {
                display: inline;
            }
        }

        p + p {
            margin-top: rem(21);
        }

        &-desc {
            padding: 0;
            margin-top: rem(34);

            @include font(18, 27);
            @include noe-display-thin;

            @include media('>=md') {
                display: none;
            }

            p {
                margin: 0
            }
        }
    }

    .capabilities {
        margin-right: rem(42);

        @include media('>=md') {
            margin-right: rem(0);
        }

        &__caption {
            @include font(18, 20);
            @include theinhardt-regular;

            margin-bottom: rem(20);

            @include media('>=md') {
                @include font(24, 26);
            }
        }

        &__list {
            display: flex;
            flex-direction: column;

            list-style: none;

            margin: 0 0 rem(57) 0;
            padding: 0;

            @include media('>=md') {
                margin: 0 0 rem(55) 0;
            }

            li + li {
                margin-top: rem(8);
                padding-top: rem(9);

                border-top: rem(1) solid $color--7;
            }
        }

        &__single {
            p {
                margin-bottom: 0;
            }
        }
    }

    .experts {
        margin-top: rem(45);

        &__caption {
            @include font(18, 20);
            @include theinhardt-regular;

            margin-bottom: rem(18);

            @include media('>=md') {
                @include font(24, 26);
            }
        }

        &__list {
            display: flex;
            flex-direction: column;
            gap: rem(19);

            list-style: none;

            padding: 0;
            margin: 0;

            @include media('>=md') {
                gap: rem(24);
            }
        }

        &__single {
            display: flex;
            align-items: center;
            gap: rem(14.5);

            &-img {
                height: rem(48);
                width: rem(48);
                min-width: rem(48);

                img {
                    width: 100%;
                    height: 100%;

                    object-fit: cover;

                    border-radius: 50%;
                }
            }

            &-content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }

            &-name {
                @include theinhardt-medium;
            }

            &-desc {
                @include theinhardt-light;
                @include font(14, 17);
            }
        }
    }

}
