@import "__settings/init";

.cta-section {
    padding-top: rem(60);
    padding-bottom: rem(70);

    @include media('>=lg') {
        padding-top: rem(97);
        padding-bottom: rem(128);
    }

    &__title {
        @include font(50, 56);
        @include noe-display-thin;
        letter-spacing: -0.01em;
        text-align: center;

        margin-bottom: rem(33);

        @include media('>=lg') {
            @include font(90, 72);
        }
    }

    .list-links {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: rem(30);

        margin: 0;
        padding: 0;

        list-style: none;

        @include media('>=lg') {
            gap: rem(60);
        }

        &__item {
            @include font(16, 18);
            @include theinhardt-light;
            position: relative;

            @include media('>=lg') {
                @include font(18, 20);
            }

            &::after {
                content: "";
                display: block;
                position: absolute;
                transition: width 0.3s cubic-bezier(0.25, 0.1, 0.25, 1) 0s;
                right: 0;
                bottom: rem(-3);
                height: rem(1);
                background-color: currentColor;
                width: 0;

                @include media('>=lg') {
                    bottom: rem(-13);
                }
              }

            &:hover {
                &::after {
                    left: 0;
                    right: auto;
                    width: 100%;
                }
            }
        }

        &__link {
            font-weight: inherit;
        }
    }
}