@import "__settings/init";

.projects-slider {
    padding-top: rem(18);

    overflow: hidden;
    @include media('>=md') {
        overflow: unset;
    }
    @include media('>=lg') {
        padding-top: rem(120);
        padding-bottom: rem(85);
    }

    &.style--white {
        .splide__pagination__page {
            color: $color--dark;
        }
    }

    &.style--black {
        .splide__pagination__page {
            color: $color--light;
        }
    }

    &__container {
        max-width: rem(1920);
        margin: 0 auto;
        // padding: 0 rem(31);
    }

    &__title {
        @include font(80, 64);
        @include noe-display-regular;
        letter-spacing: -0.01em;
        text-align: center;

        @include media('>=md') {
			@include font(120, 120);
		}

        @include media('>=lg') {
            @include font(250, 200);
        }

        @include media('<=md') {
            margin-left: rem(-21);
            margin-right: rem(-21);
        }
    }

    &__slider {
        margin-top: rem(0);
        margin-right: rem(0);
        padding-bottom: rem(40);
        padding-left: rem(32);
        -ms-overflow-style: none;
        scrollbar-width: none;
        overflow-x: auto;
        &::-webkit-scrollbar {
            display: none;
        }

        @include media('>=sm') {
            padding-left: rem(50);
        }

        @include media('>=lg') {
            position: sticky;
            top: 0;
            overflow-x: hidden;
            overflow-y: hidden;

            -ms-overflow-style: inherit;
            scrollbar-width: inherit;

            margin-top: rem(-63);
            padding-bottom: rem(65);
        }

        @include media('>=xl') {
            padding-left: rem(84);
        }

        @include media('>=1920px') {
            top: 50%;
            transform: translateY(-50%);
            margin-top: 25%;
            padding-bottom: rem(0);

        }

        &-inner {
            display: flex;
            gap: rem(17);

            &::after {
                content: "";
                display: block;
                min-height: rem(1);
                min-width: rem(15);
                position: relative;

                @include media('>=sm') {
                    display: none;
                }
            }

            @include media('>=sm') {
                gap: rem(50);
            }

            @include media('>=xl') {
                gap: rem(84);
            }
        }


    }

    .project-slide {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        width: calc(50vw - 39.5px);
        min-width: calc(50vw - 39.5px);

        height: 100%;

        @include media('>=md') {
            flex-direction: row;
        }

        &:first-child {
            margin-top: rem(44);

            gap: rem(8);


            @include media('>=md') {
                min-width: rem(277);

                margin-top: rem(33);

                flex-direction: column;
                gap: rem(18);
            }

            .project-slide__image {
                @include media('>=md') {
                    width: rem(277);
                    height: rem(356);
                }
            }
        }

        &:nth-child(2) {
            margin-top: rem(84);

            gap: rem(17);
            @include media('>=md') {
                min-width: rem(397);
                width: rem(397);

                margin-top: rem(121);
            }

            .project-slide__content {
                @include media('>=md') {
                    gap: 0;
                }
            }

            .project-slide__image {
                @include media('>=md') {
                    width: rem(397);
                    height: rem(392);
                }
            }

            .project-slide__image-alt {
                width: rem(219);
                height: rem(219);
            }
        }

        &:nth-child(3) {
            margin-top: rem(10);

            align-items: flex-end;
            gap: rem(19);

            position: relative;

            @include media('>=md') {
                min-width: rem(801);
                width: rem(801);

                margin-top: 0;
            }

            .project-slide__image {
                @include media('>=md') {
                    width: rem(440);
                    height: rem(644);
                }
            }

            .project-slide__image-alt {
                width: rem(341);
                height: rem(219);
            }

            .project-slide__content {
                @include media('>=md') {
                    position: absolute;
                    width: rem(341);
                    right: 0;
                    bottom: rem(232);
                }
            }

        }

        &:nth-child(4) {
            margin-top: rem(80);

            gap: rem(21);

            position: relative;

            @include media('>=md') {
                min-width: rem(638);
                width: rem(638);

                margin-top: rem(240);
            }

            .project-slide__image {
                @include media('>=md') {
                    width: rem(219);
                    height: rem(270);
                }
            }

            .project-slide__image-alt {
                width: rem(397);
                height: rem(504);
            }

            .project-slide__content {
                @include media('>=md') {
                    position: absolute;
                    width: rem(219);
                    left: 0;
                    top: rem(287);
                }
            }
        }

        &:nth-child(5) {
            margin-top: rem(33);

            gap: rem(19);

            @include media('>=md') {
                min-width: rem(640);
                width: rem(640);
                margin-right: rem(50);
                flex-direction: column;
            }

            @include media('>=xl') {
                min-width: rem(758);
                width: rem(758);
                margin-right: rem(168);
            }

            .project-slide__image {
                @include media('>=md') {
                    width: rem(590);
                    height: rem(453);
                }
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: rem(2);

            @include media('>=md') {
                gap: rem(3);
            }
        }

        &__caption {
            @include font(12, 13);
            @include theinhardt-regular;

        }
        &__title {
            display: flex;

            margin-bottom: rem(0);

            @include font(18, 23);
            @include noe-display-thin;

            @include media('>=md') {
                @include font(24, 26);
            }
        }
        &__image {
            position: relative;

            @include media('<=md') {
                height: rem(189);
                width: 100%;
            }

            &-alt {
                display: none;

                a {
                    overflow: hidden;
                    width: var(--width);
                    height: 100%;
                }

                @include media('>=md') {
                    display: block;
                }
            }

            a {
                overflow: hidden;
                width: var(--width);
                height: 100%;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
            }
        }
    }

    &__pagination {
        position: absolute;
        left: 50%;
        bottom: rem(15);
        transform: translateX(-50%);
        z-index: 1;
        transition: bottom 0.3s ease-in-out;

        @include media('>=lg') {
            bottom: rem(60);
        }

        &.fixed {
            position: fixed;
        }

        li:last-child {
            display: none;
        }
    }
}