@import '__settings/init';

.intro-title {
	padding: rem(119) 0 rem(10);

	overflow: hidden;

	@include media('>=lg') {
		padding: rem(197) 0 rem(76);
	}

	&__text {
		padding-bottom: rem(35);

		letter-spacing: -.01em;

		@include noe-display-regular;
		@include font(80, 64);
		@include media('>=sm') {
			padding-left: 5.75vw;

			text-align: right;
		}

		@include media('>=md') {
			@include font(120, 120);
			white-space: nowrap;
		}

		@include media('>=lg') {
			padding-bottom: rem(56);

			@include font(150, 150);
		}
		@include media('>=xxl') {
			@include font(180, 180);
			text-align: center;

			@include font(220, 200);
		}
	}

	.sound {
		display: block;

		position: relative;

		text-align: right;
		cursor: pointer;

		@include media('>=sm') {
			display: inline-block;
		}

		audio {
			display: none;
		}

		&.cursor-change-sound--mac {
			.sound__shape--desktop {
				@include media('>=sm') {
					bottom: rem(-54);
				}

				@include media('>=lg') {
					bottom: rem(-95);
				}
			}
		}

		&__shape {
			position: absolute;

			&--desktop {
				display: none;
				opacity: 0;

				@include media('>=sm') {
					display: block;

					width: 70%;

					bottom: rem(-30);
					left: rem(12);

					transition: opacity .3s ease-in-out;

					svg {
						transform: scaleX(-1);
					}
				}
				@include media('>=lg') {
					bottom: rem(-44.8);
				}

				@include media('>=xxl') {
					left: rem(18);
					bottom: rem(-66);
				}
			}

			&--mobile {
				display: block;

				width: rem(32);
				height: rem(32);

				z-index: 1;
				top: rem(-50);
				right: 7.2vw;

				@include media('>=sm') {
					display: none;
				}
			}
		}

		@media (hover: hover) {
			&:hover {
				.sound__shape--desktop {
					opacity: 1;
				}
			}
		}
		&.playing {
			.sound__shape--desktop {
				opacity: 1;
			}
		}
	}
}
