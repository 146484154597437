.btn-menu {
	display: flex;

	position: relative;

	border: 0;
	background: transparent;
	box-shadow: none;
	cursor: pointer;

	transition: .5s ease-in-out;

	color: currentColor;

	padding: 0;
	margin-top: rem(-2);

	@include media('>=lg') {
		display: none;
	}
	span {
		@include font(14, 12.6);

		&:first-child {
			display: inline-block;
		}
		&:last-child {
			display: none;
		}
	}
	&[aria-expanded="true"] {
		span {
			&:first-child {
				display: none;
			}
			&:last-child {
				display: inline-block;
			}
		}
	}
}
