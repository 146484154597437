@import '__settings/init';

.downloads__modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 0;

    transition: opacity 0.3s ease-in-out;
    pointer-events: none;

    z-index: 3;

    color: $color--dark;

    &.active {
        opacity: 1;
        pointer-events: all;

        .downloads__modal-background {
            opacity: 1;
        }
    }

    &-background {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.4);
    }

    &-inner {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        background-color: $color--light;

        max-width: rem(798);
        width: 100%;
        padding: rem(15) rem(21) rem(52) rem(21);
        margin: 0 rem(32);

        z-index: 1;

        @include media('>=md') {
            padding: rem(25) rem(41) rem(59) rem(120);
        }
    }

    &-close {
        display: flex;
        align-items: center;
        gap: rem(5);

        align-self: flex-end;

        @include font(14, 12);
        padding: rem(7) rem(0) rem(7) 0;
        margin-bottom: rem(12);
        margin-right: rem(-7);

        cursor: pointer;

        @include media('>=md') {
            padding: rem(7) rem(8) rem(7) rem(0);
            margin-bottom: rem(34);
            margin-right: rem(0);
        }
    }

    &-logo {
        margin-bottom: rem(27);
    }

    &-form {
        width: 100%;
        max-width: rem(557);
        color: $color--dark;

        .wpcf7 {
            .cf7-form-title {
                max-width: rem(356);
            }

            .cf7-align-bottom {
                max-width: rem(459);
            }

            .cf7-input-submit-row {
                & > *:nth-child(odd) {
                    width: 100%;
                }
                & > *:nth-child(even) {
                    min-width: rem(72);
                    max-width: rem(72);
                }
            }

            .wpcf7-list-item .wpcf7-list-item-label a {
                text-decoration-color: rgba(0, 0, 0, 0.4);
            }

            input, textarea {
                color: $color--dark;

                &::placeholder, &:focus::-webkit-input-placeholder {
                    opacity: 1;
                    color: $color--dark;
                }
            }

            .cf7-submit-wrap::after {
                filter: invert(1);
            }
            .wpcf7-list-item .wpcf7-list-item-label::before {
                border: rem(1) solid $color--dark;
            }
            .wpcf7-list-item .wpcf7-list-item-label::after {
                border-bottom: 0.125rem solid $color--dark;
                border-right: 0.125rem solid $color--dark;
            }
            .wpcf7-list-item {
                color: rgba(0, 0, 0, 0.7);
            }
            input[type="submit"] {
                border-bottom: rem(1) solid rgba(0, 0, 0, 0.2);
            }
        }
    }
}
