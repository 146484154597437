@import '__settings/init';

.hero {
	& > .cursor-change {
		overflow: hidden;
	}

	&.style--white {
		.video__content {
			background-color: rgba(0,0,0, .9);
		}
	}

	&.style--black {
		.video__content {
			background-color: rgba(0,0,0, .9);
		}
	}

	&__container {
		max-width: rem(1177);
	}

	&__heading {
		padding-top: rem(94);

		@include media('>=lg') {
			padding-top: rem(45);
		}
	}

	&__title {
		@include font(60, 60);
		@include noe-display-thin;
		@include media('>=350px') {
			@include font(80, 80);
		}

		@include media('>=md') {
			@include font(120, 120);
		}

		@include media('>=lg') {
			@include font(160, 160);
		}
	}

	&__video {
		width: 100%;
		max-width: rem(878);

		position: relative;
		z-index: 1;
		margin: rem(24) auto rem(-61);
		backface-visibility: hiiden;
		transform-style: preserve-3d;
		will-change: transform;

		opacity: 0;
		visibility: hidden;
		transition: opacity $default-transition-speed, visibility $default-transition-speed;

		&.hero-loaded {
			opacity: 1;
			visibility: visible;
		}

		@include media('>=lg') {
			height: rem(482);

			margin: rem(64) auto rem(-201);
		}

		.video {
			position: relative;

			&:after {
				content: '';

				display: block;

				padding-bottom: 54.89%;
			}

			&__thumbnail {
				cursor: pointer;

				border-radius: rem(5);
				box-shadow: 0 0 rem(50) rem(10) rgba(0, 0, 0, .3);
				overflow: hidden;
				position: absolute;
				transform: translate(-50%, -50%);
				top: 50%;
				left: 50%;
				width: 100%;
				height: 100%;
				backface-visibility: hidden;
				transform-style: preserve-3d;
				will-change: transform;

				video,
				img {
					width: 100%;
					height: 100%;
					backface-visibility: hidden;
					transform-style: preserve-3d;


					top: 0;
					left: 0;

					object-fit: cover;
				}
			}

			&__btn {
				padding: 0;

				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%) scale(0.5);

				border: none;
				border-radius: rem(20);
				background: none;
				box-shadow: 0 0 rem(34) rgba(0, 0, 0, .24);
				overflow: hidden;
				z-index: 1;
				pointer-events: none;

				@include media('>=lg') {
					transform: translate(-50%, -50%);

					display: none;
				}

				&-play {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					margin-left: rem(4);
				}
			}
		}
	}
}

.hero-03 {
	padding-bottom: rem(0);

	@include media('>=lg') {
		padding-bottom: rem(30);
	}


	.hero {
		&__title {
			max-width: rem(880);

			@include font(50, 55);

			@include media('>=md') {
				@include font(120, 120);
			}

			@include media('>=lg') {
				@include font(160, 160);
			}
		}
		&__video {
			width: auto;
			height: auto;

			margin: rem(37) rem(-31) rem(0) auto;

			border-radius: 0;
			box-shadow: none;

			@include media('>=lg') {
				width: rem(917);
			}

			@include media('>=xl') {
				margin: rem(45) rem(0) rem(0) auto;
			}

			.video__thumbnail {
				cursor: auto;
				box-shadow: none;
				border-radius: 0;

				iframe {
					object-fit: cover;
					pointer-events: none;

					width: 100%;
					height: 100%;
				}
			}

			.video {
				&::after {
					    padding-bottom: 56.8%;
				}
			}
		}
	}
}

.hero, .hero-03, .hero-04, .hero-05 {
	.video {
		&__content {
			display: none;

			width: 100%;
			height: 100%;
			height: 100%;

			position: fixed;
			z-index: 100;
			top: 0;
			left: 0;

			opacity: 0;

			transition: opacity $default-transition-speed ease-in-out;
			z-index: 110;

			&.show {
				display: block;
			}

			&.visible {
				opacity: 1;
			}

			iframe {
				width: 100%;
				height: 100%;

				position: absolute;
				top: 0;
				left: 0;

				object-fit: cover;
			}
		}

		&__content-wrapper {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 90%;

			@include media('>=md') {
				width: 85%;
			}

			&:after {
				content: "";
				display: block;
				padding-bottom: 56.25%;
			}
		}

		&__iframe {
			width: 100%;
			height: 100%;
		}

		&__close {
			display: flex;
			align-items: center;
			justify-content: center;

			width: rem(25);
			height: rem(25);
			padding: 0;

			position: absolute;
			z-index: 100;
			top: 2.3rem;
			right: 2.3rem;
			border: none;
			background-color: transparent;
			transition: transform $default-transition-speed ease-in-out;

			&:hover {
				transform: rotate(90deg);
			}
		}
	}

	&.style--white {
		.video__content {
			background-color: rgba(0,0,0, .9);
		}
	}

	&.style--black {
		.video__content {
			background-color: rgba(0,0,0, .9);
		}
	}
}
