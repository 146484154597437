@import "__settings/init";

.large-title {
	position: relative;
	padding-bottom: 0 0 rem(45);

	max-width: rem(1440);
	width: 100%;

	margin: 0 auto;

	@include media(">=lg") {
		padding-top: rem(20);
		padding-bottom: rem(120);
	}

	&--has-background {
		z-index: 1;
		overflow: hidden;

		.large-title__container {
			padding-top: rem(170);

			@include media(">=lg") {
				padding-top: rem(330);
			}
		}

		.large-title__bg {
			top: rem(50);

			@include media(">=lg") {
				top: rem(150);
			}
		}
	}

	&__container {
		position: relative;
	}

	&__title {
		@include font(50, 45);
		@include noe-display-regular;
		letter-spacing: -0.01em;
		display: block;

		@include media('>=350px') {
			@include font(80, 64);
		}

		.animated-line {
			margin: 0 auto;
			max-width: rem(360);

			@include media(">=md") {
				max-width: rem(490);
			}

			@include media(">=lg") {
				max-width: rem(1420);
			}

			&-wrapper {
				display: block;

				&:nth-child(1n) {
					padding: 0;
					text-align: center;

					@include media(">=lg") {
						text-align: right;
					}
				}

				&:nth-child(2n) {
					padding: 0 0 0 5vw;
					text-align: left;

					@include media(">xxl") {
						padding: 0 0 0 19vw;
					}
				}

				&:nth-child(3n) {
					text-align: right;
					padding: 0 5vw 0 0;

					@include media(">=lg") {
						padding: 0 10vw 0 0;
					}

					@include media(">xxl") {
						padding: 0 19vw 0 0;
					}
				}
			}
		}
		@include media('>=md') {
			@include font(120, 120);
		}

		@include media(">=lg") {
			@include font(250, 200);
		}
	}
}

.scroll-tabs--black {
	.large-title {
		.animated-line {
			max-width: 100%;

			@include media(">=lg") {
				max-width: rem(1130);
				margin: 0 auto;
			}

			&-wrapper {
				text-align: center;
				padding: 0;

				@include media(">=lg") {
					&:nth-child(1n) {
						text-align: right;
					}

					&:nth-child(2n) {
						text-align: left;
					}
				}
			}
		}
	}
}
