@import "__settings/init";

.wp-block-button {
	display: inline-flex;
	align-items: center;
	position: relative;

	&::before,
	&::after {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		bottom: rem(-1);
		height: rem(1);
		background-color: currentColor;
	}

	&::before {
		width: 100%;
		opacity: 1;
		z-index: 1;

		@include media('>=lg') {
			opacity: 0.2;
		}
	}

	&::after {
		width: 0;
		z-index: 2;
		transition: width 0.3s ease-in-out;

		@include media('<=lg') {
			display: none;
		}
	}

	&:hover {
		&::after {
			width: 100%;
			transition: width 0.3s ease-in-out;
		}
		svg {
			@include media('>=lg') {
				opacity: 1;
				transform: translateX(3px);
				transition: all 0.3s ease-in-out;
			}
		}
	}

	&.arrow-left {
		.wp-block-button__link {
			flex-direction: row-reverse;

			svg {
				transform: scale(-1);
			}

			&:hover {
				svg {
					transform: scale(-1) translate(3px);
				}
			}
		}
	}

	.wp-block-button__link {
		display: flex;
		align-items: center;
		gap: rem(9);
		background-color: transparent;
		color: inherit;
		padding: 0;
		@include theinhardt-bold;
		@include font(14, 17);
		letter-spacing: 0.08em;
		text-transform: uppercase;
		padding-bottom: rem(7);
	}

	svg {
		transition: all 0.3s ease-in-out;

		@include media('>=lg') {
			opacity: 0.2;
		}

		path {
			fill: currentColor;
		}
	}
}
