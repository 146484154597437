@import '__settings/init';

.scroll-tabs {
    position: relative;

    padding-top: rem(50);
    padding-bottom: rem(70);

    @include media('>=lg') {
        padding-top: rem(75);
        padding-bottom: rem(176);
    }

    .large-title {
        @include media('<=md') {
            padding-top: rem(5);
        }

        &.large-title--has-background {
            .large-title__title {
                .animated-line-wrapper:nth-child(2n) {
                    @include media(">xxl") {
                        padding: 0 0 0 2vw;
                    }
                }
                .animated-line-wrapper:nth-child(3n) {
                    @include media(">xxl") {
                        padding: 0 10vw 0 0;
                    }
                }
            }
        }
        &__title {
            @include media('>=350px') {
                @include font(67, 53);
            }
            @include media('>=md') {
                @include font(120, 120);
            }
            @include media(">=lg") {
                @include font(250, 200);
            }
        }
    }

    .intro {
        @include media('<=lg') {
            padding-bottom: rem(30);
        }

        &__caption {
            margin-bottom: rem(38);

            @include media('>=md') {
                margin-bottom: rem(81);
            }
        }
        &__col {
            &--desc {
                margin-top: rem(0);

                @include media('>=md') {
                    margin-top: rem(38);
                }
            }
        }
    }

    &.scroll-tabs--black {
        .large-title__title {
			@include noe-display-regular;

			@include media(">=xl") {
				@include noe-display-thin;
			}
        }
    }

    &__gradient {
        display: none;

        background-size: 100% rem(295);
		background-position: top;
		background-repeat: no-repeat;

		width: 100%;
		height: rem(295);

        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        z-index: -1;

        @include media('>=lg') {
            background-size: 100% rem(600);

            height: rem(600);
        }
    }

    &__container {
        padding-top: rem(0);
        display: flex;

        @include media('>=lg') {
            padding-top: rem(80);
        }

        @include media('<=lg') {
            padding-left: rem(28);
        }

        &__helper {
            width: rem(1);
            height: var(--scroll-helper-height);

            &__list {
                display: none;
                flex-direction: column;
                width: rem(1);

                @include media('>=lg') {
                    display: flex;
                }
            }
        }
    }

    &__content {
        gap: rem(37);
        position: relative;
        display: flex;
        margin-right: 0;
        width: 100%;

        @include media('>=xxl') {
            margin-right: rem(-160);
        }
    }

    &__list {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: rem(20);
        padding-left: rem(24);
        width: 100%;
        position: sticky;
        top: rem(50);
        align-self: flex-start;

        &::before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            opacity: 0.3;
            background-color: currentColor;
            height: 100%;
            width: rem(1);
        }

        @include media('>=lg') {
            padding-left: rem(31);
            gap: rem(30);
            top: rem(170);
        }

        @include media('>=xl') {
            padding-left: rem(74);
        }

        @include media('>=xxl') {
            padding-left: rem(202);
        }

        @include media('>=1800px') {
            top: var(--l-s-top-pos);
        }

        &-left {
            width: 100%;

            @include media('>=lg') {
                max-width: rem(362);
                min-width: rem(362);
            }
        }

        &-item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: rem(0);

            position: relative;

            @include media('>=lg') {
                flex-direction: row;
                gap: rem(37);
            }

            @include media('<=lg') {
                transition: all 0.3s ease-in-out;

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    height: 100%;
                    width: rem(1);
                    top: 0;
                    bottom: 0;
                    left: rem(-24);
                    opacity: 0;
                    background-color: currentColor;
                    transition: opacity 0.3s ease-in-out;
                }
            }

            &:last-child {
                .scroll-tabs__item-inner--image {
                    margin-bottom: 0;
                }
            }

            &.active {
                @include media('<=lg') {
                    gap: rem(24);

                    &::before {
                        opacity: 1;
                        transition: opacity 0.3s ease-in-out;
                    }
                }
                .scroll-tabs__list-title {
                    opacity: 1;
                    transition: opacity 0.3s ease-in-out;

                    @include media('>=lg') {
                        .scroll-tabs__list-line {
                            width: 100%;
                            transition: width 0.3s ease-in-out;
                        }
                    }
                }

                .scroll-tabs__list-description {
                    height: auto;

                    margin-top: rem(25);

                    @include media('>=lg') {
                        margin-top: rem(14);
                    }
                }

                .scroll-tabs__list-icon {

                    &::after {
                        transform: rotate(0);
                        opacity: 0;
                        transition: all 0.3s ease-in-out;
                    }
                }
            }
        }

        &-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include font(26, 29);
            @include noe-display-thin;
            border: none;
            background: transparent;
            padding: 0;
            text-align: left;
            width: 100%;
            opacity: 0.3;
            transition: opacity 0.3s ease-in-out;
            color: currentColor;

            @include media('>=350px') {
                white-space: nowrap;
            }

            &:hover {
                @include media('>=lg') {
                    opacity: 1;
                    transition: opacity 0.3s ease-in-out;
                }
            }

            @include media('>=lg') {
                @include font(35, 39);
                gap: rem(20);
                align-items: flex-start;
            }
            span {
                max-width: rem(220);

                @include media('>=lg') {
                    max-width: rem(300);
                }
            }

        }

        &-description {
            margin-top: 0;
            margin-right: rem(32);

            height: 0;

            overflow: hidden;

            transition: height 0.3s, margin 0.3s ease-in-out;

            @include media('>=lg') {
                margin-left: rem(0);
                margin-right: rem(0);

                max-width: rem(273);
            }
        }

        &-text {
            margin: 0 0 0 rem(42);

            @include media('>=lg') {
                margin: 0 0 0 0;
            }
        }

        &-line {
            display: block;
            width: 0;
            height: rem(1);
            background-color: currentColor;
            margin-top: rem(19);
            transition: width 0.3s ease-in-out;
        }

        &-icon {
            display: block;
            position: relative;

            height: rem(2.5);
            width: rem(14);

            @include media('>=lg') {
                display: none;
            }

            &::before, &::after {
                content: '';
                display: block;
                position: absolute;
                height: rem(2.5);
                width: rem(14);
                background-color: currentColor;
            }
            &::after {
                transform: rotate(90deg);
                opacity: 1;
                transition: all 0.3s ease-in-out;
            }
        }

        &-right {
            height: 0;
			// overflow: hidden;
            opacity: 0;
            transition: height 0.3s, opacity 0.3s ease-in-out;

            &.active {
                height: auto;

                opacity: 1;

                @include media('>=lg') {
                    .scroll-tabs__item-inner {
                        &::before {
                            opacity: 1;
                            transition: opacity 0.3s ease-in-out;
                        }
                    }
                }

                @include media('<=lg') {
                    margin-bottom: rem(32);
                }
            }
        }
    }

    &__items {
        margin-top: rem(-30);
    }

    &__item {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: rem(20);

        @include media('>=sm') {
            flex-direction: row;
            gap: rem(35);
        }

        @include media('>=xl') {
            gap: rem(85);
        }

        &-inner {
            width: 100%;
            position: relative;

            @include media('>=lg') {
                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    height: 100%;
                    width: rem(1);
                    top: 0;
                    bottom: 0;
                    left: rem(-430);
                    opacity: 0;
                    background-color: currentColor;
                    transition: opacity 0.3s ease-in-out;

                    @include media('>=xl') {
                        left: rem(-473);
                    }

                    @include media('>=xxl') {
                        left: rem(-601);
                    }
                }
            }

            @include media('<=lg') {
                padding-left: rem(42);
            }

            &--image {
                $scrollbar: var(--scrollbar-width);
                @include media('>=lg') {
                    margin-bottom: rem(47);

                    $width: calc(((100vw - $container-xxl) / 2) + 357px - $scrollbar);
                    min-width: $width;
                }

                @include media('>=xl') {
                    $width: calc(((100vw - $container-xxl) / 2) + 196px - $scrollbar);
                    min-width: $width;
                }

                @include media('>=xxl') {
                    $width: calc(((100vw - $container-xxl) / 2) + 68px - $scrollbar);
                    min-width: $width;
                }
            }
        }

        &-title {
            @include theinhardt-regular;
            @include font(26, 29);
            letter-spacing: -0.01em;
            margin-bottom: rem(14);

            @include media('>=lg') {
                @include font(35, 39);
                margin-bottom: rem(9);
            }
        }

        &-content {
            margin-bottom: 0;

            @include theinhardt-regular;
            @include font(16, 22);

            &--title {
                @include noe-display-thin;
                @include font(22, 35);

                max-width: rem(475);

                margin-top: rem(4);
            }

            p {
                margin: 0;
            }
        }
        .wp-block-button {
            margin-top: rem(28);

            @include media('>=lg') {
                margin-top: rem(26);
            }
        }

        &-image {
            min-width: rem(250);
            max-width: rem(250);
            position: relative;

            @include media('>=lg') {
                margin-bottom: rem(11);
            }

            @include media('>=xl') {
                min-width: rem(397);
                max-width: rem(397);
                margin-top: rem(-31);
            }

            img {
                min-width: rem(250);
                max-width: rem(250);
                min-height: rem(180);
                object-fit: cover;

                @include media('>=lg') {
                    position: absolute;
                }

                @include media('>=xl') {
                    min-width: rem(397);
                    max-width: rem(397);
                    min-height: rem(269);
                }
            }
        }
    }

    .author-quote {
        max-width: rem(406);
        width: 100%;

        margin: 0;
        padding-top: rem(5);

        &__text {
            margin-top: 0;
            margin-bottom: rem(18);

            @include font(18, 27);
            @include noe-display-thin;
            text-indent: rem(-7);

            quotes: "“" "”" "‘" "’";

            @include media('>=lg') {
                @include font(22, 35);

                margin-bottom: rem(16);
            }

            &::before {
                content: open-quote;
            }

            &::after {
                content: close-quote;
            }
        }

        &__footer {
            display: flex;
            align-items: flex-start;
            gap: rem(15);

        }

        &__img {
            min-width: rem(48);
            width: rem(48);
            height: rem(48);

            border-radius: 50%;

            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__author {
            display: flex;
            flex-direction: column;

            &-name {
                @include font(16, 19);
                @include theinhardt-medium;
            }

            &-desc {
                @include font(14, 17);
                @include theinhardt-light;

                max-width: rem(157);
            }
        }
    }
}
