@import '__settings/init';
.splide {

	&__pagination {
		display: flex;
		align-items: center;
		gap: rem(12);

		padding: 0;
		margin: 0;
		list-style: none;

		li {
			font-size: 0;

			display: flex;
    		align-items: center;
		}
	}
	&__pagination__page {
		margin: 0;
		padding: rem(10);

		background-color: inherit;

		border-radius: 50%;
		border: none;
		background: transparent;

		position: relative;

		&::before {
			content: '';

			display: block;

			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);

			width: rem(4);
			height: rem(4);

			background-color: currentColor;
			color: currentColor;

			border-radius: 50%;
		}


		&.is-active {
			width: rem(10);
			height: rem(10);
			background-color: transparent;
			border: rem(1) solid currentColor;
			transform: none;
			padding: rem(0);
			margin: rem(0) rem(5);

			&::before {
				display: none;
			}
		}
	}
	&__arrow--next {
		right: 0;
	}
	&__arrow--prev {
		left:0;
	}

	&__arrow {
		background: transparent;

		top: 0;
		bottom: 0;
		transform: none;

		height: 100%;
		width: 50%;

		border-radius: 0;

        transition: opacity .3s cubic-bezier(.25, .1, .25, 1) 0s;

		&:disabled {
			opacity: 0;
			visibility: hidden;
		}
	}
}