@import "__settings/init";

.rectangle-images {
	overflow: hidden;
	padding: rem(20) 0;

	@include media(">=lg") {
		padding: rem(40) 0 rem(110);
	}

	&__row {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		gap: rem(21);

		@include media(">=xl") {
			gap: rem(84);
		}
	}

	&__item {
		position: relative;
		overflow: hidden;
		width: var(--width);

		&:before {
			content: "";
			display: block;
			padding-bottom: rem(178);

			@include media(">=md") {
				padding-bottom: rem(420);
			}
		}

		> img {
			height: 120%;
			object-fit: cover;
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto;
		}

		&:first-child {
			margin-top: rem(45);

			@include media(">=lg") {
				margin-top: 0;
			}
		}

		&:nth-child(2) {
			margin-top: rem(98);

			@include media(">=lg") {
				margin-top: rem(115);
			}
		}

		&:nth-child(3) {
			@include media(">=lg") {
				margin-top: rem(210);
			}
		}

		&:nth-child(4) {
			display: none;

			@include media(">=lg") {
				display: block;
			}
		}
	}
}
