@import "__settings/init";

.logos {
	padding: rem(40) 0 rem(48);
	position: relative;
	z-index: 1;

	&.style--black {
		.logos__outro-icon {
			img {
				filter: invert(0);
			}
		}
	}
	&.style--white {
		.logos__outro-icon {
			img {
				filter: invert(1);
			}
		}
	}

	@include media(">=lg") {
		padding: rem(115) 0 0;
	}

	&__container {
		@include media(">=lg") {
			position: relative;
		}
	}

	&__heading {
		@include media(">=lg") {
			margin-bottom: rem(-100);
		}
	}
	&__title {
		text-align: center;
		@include font(60, 66);
		@include noe-display-thin();
		opacity: 0.3 !important;
		margin-bottom: rem(40);

		@include media('>=md') {
			@include font(120, 120);
		}

		@include media(">=lg") {
			@include font(130, 156);
			position: relative;
			left: 50%;
			transform: translateX(-50%);
			width: calc(100% - ($container-padding * 2px));
		}
	}

	&__rows {
		margin-bottom: rem(45);
		overflow: hidden;
		width: 100%;

		@include media(">=lg") {
			margin-bottom: rem(80);
		}

		.logos__slider {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__slider {
		display: flex;
		align-items: center;
		width: max-content;
		max-width: none;
		transform: translate3d(0, 0, 0);
		will-change: transform;
		margin-bottom: rem(10);

		&--slide-left {
			&.initialized {
				animation: slideLeft var(--rail-speed) linear infinite;
			}
		}

		&--slide-right {
			&.initialized {
				animation: slideRight var(--rail-speed) linear infinite;
			}
		}

		* {
			transform: translate3d(0, 0, 0);
		}

		@include media(">=lg") {
			margin-bottom: rem(90);
		}
	}

	&__list {
		list-style: none;
		padding-left: 0;
		margin-bottom: 0;
		display: flex;
		align-items: center;
		width: max-content;
	}

	&__item {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: rem(50);

		@include media(">=lg") {
			margin-left: rem(130);
		}

		> img {
			max-width: unset;
		}
	}

	&__outro {
		text-align: center;

		&-text {
			padding: rem(0) rem(31);
		}

		p {
			@include font(18, 27);
			@include noe-display-thin();
			margin-bottom: rem(25);

			@include media(">=lg") {
				@include font(35, 52.5);
				max-width: rem(818);
				margin: 0 auto rem(45);
			}
		}

		&-icon {
			width: rem(20);
			margin: 0 auto;

			@include media(">=lg") {
				width: rem(27);
			}
		}

		.large-title {
			padding-top: rem(109);
			padding-bottom: rem(20);

			@include media(">=lg") {
				padding-top: rem(243);
				padding-bottom: rem(60);
			}

			white-space: nowrap;

			&__title {
				@include media(">=md", "<=xl") {
					@include font(150, 150);
				}

				@include media('>=xl') {
					@include noe-display-thin;
				}

				.animated-line-wrapper:nth-child(1n) {
					margin-right: rem(19);

					text-align: right;

					@include media('>=350px') {
						margin-right: rem(-19);
					}

					@include media(">=sm") {
						margin-right: rem(19);
					}

					@include media(">=lg") {
						margin-right: rem(-12);
					}
				}
				.animated-line-wrapper:nth-child(2n) {
					padding-left: 0;
					margin-left: rem(19);

					text-align: left;

					@include media('>=350px') {
						margin-left: rem(-25);
					}

					@include media(">=sm") {
						margin-left: rem(19);
					}

					@include media(">=lg") {
						margin-left: rem(-21);
					}
				}

				.animated-line {
					max-width: unset;
				}
			}
		}
	}
}

@keyframes slideLeft {
	0% {
		transform: translate3d(0, 0, 0);
	}

	100% {
		transform: translate3d(-25%, 0, 0);
	}
}

@keyframes slideRight {
	0% {
		transform: translate3d(-50%, 0, 0);
	}

	100% {
		transform: translate3d(-25%, 0, 0);
	}
}
