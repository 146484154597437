@import '__settings/init';

.location-map {
    position: relative;

    padding-bottom: rem(70);

    overflow: hidden;

    @include media('>=md') {
        padding-top: rem(50);
    }

    @include media('>=lg') {
        overflow: unset;
    }

    @include media('>=lg') {
        padding-top: rem(0);
        padding-bottom: rem(176);
    }

    .intro {
        position: relative;

        z-index: 1;

        @include media('<=lg') {
            padding-bottom: rem(30);
        }

        &__content {
			margin: 0;

			@include media('>=xl') {
				margin: 0 0 0 rem(74);
			}

			@include media('>=xxl') {
				margin: 0 auto;
			}
        }

        &__caption {
            margin-bottom: rem(38);

            @include media('>=md') {
                margin-bottom: rem(81);
            }
        }

        &__title {
            max-width: rem(146);
            @include media('>=sm') {
                max-width: unset;
            }
        }
    }

    &__container {
        padding-top: rem(0);
        display: flex;

        @include media('>=lg') {
            padding-top: rem(27);
        }

        @include media('<=lg') {
            padding-left: rem(28);
        }

        &__helper {
            width: rem(1);
            height: var(--scroll-helper-height);

            &__list {
                display: none;
                flex-direction: column;
                width: rem(1);

                @include media('>=lg') {
                    display: flex;
                }
            }
        }
    }

    &__content {
        display: flex;
        align-items: flex-start;

        width: 100%;
    }

    &__list {
        list-style: none;
        padding: 0;
        margin: rem(28) 0 0 0;
        display: flex;
        flex-direction: column;
        gap: rem(20);
        padding-left: rem(24);
        width: 100%;
        position: sticky;
        top: rem(50);
        align-self: flex-start;
        z-index: 0;

        &::before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            opacity: 0.3;
            background-color: currentColor;
		    transition: background-color .7s cubic-bezier(.25, .1, .25, 1) 0s;
            height: 100%;
            width: rem(1);
        }

        @include media('>=sm') {
            margin: rem(300) 0 0 0;
        }

        @include media('>=md') {
            margin-top: 0;
        }

        @include media('>=lg') {
            padding-left: rem(31);
            gap: rem(20);
            top: rem(170);
        }

        @include media('>=xl') {
            padding-left: rem(74);
        }

        @include media('>=xxl') {
            padding-left: rem(202);
        }

        .location-map__list-item {
            width: 100%;

            transition: margin 0.2s ease-in-out;

            position: relative;

            @include media('>=md') {
                max-width: rem(216);
            }

            @include media('>=lg') {
                max-width: rem(160);
            }

            &:nth-child(6) {
                &.active {
                    margin-bottom: 0;
                }
            }

            &.active {
                margin-bottom: rem(50);

                @include media('>=xl') {
                    margin-bottom: rem(94);
                }

                .location-map__title {
                    opacity: 1;
                }

                &::before {
                    opacity: 1;
                    transition: all 0.2s ease-in-out;
                }
            }

            &::before {
                content: '';
                display: block;
                position: absolute;
                width: rem(1);
                height: 100%;
                top: 0;
                bottom: 0;
                left: rem(-24);
                background-color: currentColor;
                opacity: 0;
                transition: all 0.2s ease-in-out;
		        transition: background-color .7s cubic-bezier(.25, .1, .25, 1) 0s;

                @include media('>=lg') {
                    left: rem(-31);
                }

                @include media('>=xl') {
                    left: rem(-74);
                }

                @include media('>=xxl') {
                    left: rem(-202);
                }
            }
        }
    }

    &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include font(26, 29);
        @include noe-display-thin;
        border: none;
        background: transparent;
        padding: 0;
        text-align: left;
        width: 100%;
        opacity: 0.3;
        color: currentColor;

        &:hover {
            transition: opacity .3s ease-in-out, color .3s cubic-bezier(.25, .1, .25, 1) 0s;
        }

        @include media('>=350px') {
            white-space: nowrap;
        }

        &.active {
            .location-map__icon {
                &::after {
                    transform: rotate(0);
                }
            }
        }

        &:hover {
            @include media('>=lg') {
                opacity: 1;
                transition: opacity .3s ease-in-out;
            }
        }

        @include media('>=lg') {
            @include font(35, 39);
            gap: rem(20);
            align-items: flex-start;
        }
        span {
            max-width: rem(220);

            @include media('>=lg') {
                max-width: rem(300);
            }
        }

    }

    &__icon {
        display: block;
        position: relative;

        height: rem(2.5);
        width: rem(14);

        @include media('>=lg') {
            display: none;
        }

        &::before, &::after {
            content: '';
            display: block;
            position: absolute;
            height: rem(2.5);
            width: rem(14);
            background-color: currentColor;
        }
        &::after {
            transform: rotate(90deg);
            opacity: 1;
            transition: all .3s ease-in-out;
        }
    }

    &__info {
        height: 0;
        opacity: 0;
        overflow: hidden;
        transition: height .3s;

        @include media('>=lg') {
            transition: height .3s, opacity .3s ease-in-out;
        }

        &.active {
            height: auto;
            opacity: 1;
            overflow: unset;
        }

        &-text {
            @include font(16, 26);

            margin-top: rem(20);
            margin-bottom: rem(30);

            p {
                margin: 0;
            }

            @include media('>=md') {
                max-width: rem(166);
            }

            @include media('>=lg') {
                max-width: unset;

                margin-top: rem(0);
            }
        }
    }

    &__map {
        position: absolute;
        top: rem(-240);
        left: rem(-31);
        z-index: -1;
        width: calc(100vw - var(--width));
        transform: scale(1.7);
		transition: fill .3s cubic-bezier(.25, .1, .25, 1), stroke .3s cubic-bezier(.25, .1, .25, 1);

        &::after, &::before {
            content: "";

            display: block;

            position: absolute;
            z-index: 1;

            bottom: 0;
            left: 0;
            right: 0;

            height: rem(80);

            transition: opacity .3s cubic-bezier(.25, .1, .25, 1);
            z-index: 0;

            @include media('>=md') {
                height: rem(170);
            }

            @include media('>=lg') {
                height: rem(200);
            }

            @include media('>=xl') {
                height: rem(240);
            }

            @include media('>=xxl') {
                height: rem(285);
            }
            @include media('>=1800px') {
                height: rem(400);
            }
        }

        &::before {
            background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%,rgba(255, 255, 255, 1) 100%);
            background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%,rgba(255, 255, 255, 1) 100%);
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%,rgba(255, 255, 255, 1) 100%);
            opacity: 1;

            @include media('>=xxl') {
                background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 100%);
                background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 100%);
                background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 100%);
            }

            .style--black & {
                opacity: 0;
            }
        }

        &::after {
            opacity: 0;
            background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 1) 100%);
            background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 1) 100%);
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 1) 100%);

            @include media('>=xxl') {
                background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 50%, rgb(0, 0, 0) 100%);
                background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 50%, rgb(0, 0, 0) 100%);
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 50%, rgb(0, 0, 0) 100%);
            }

            .style--black & {
                opacity: 1;
            }
        }

        @include media('>=450px') {
            top: rem(-288);
            transform: scale(1.5);
        }

        @include media('>=sm') {
            top: rem(-400);
            transform: scale(1.5) translateX(-50px);
        }

        @include media('>=md') {
            top: rem(-100);
            transform: none;
        }
        @include media('>=lg') {
            top: rem(-200);
            left: rem(-41);
        }
        @include media('>=1170px') {
            $left: calc(-31px - (((100vw - var(--width)) - $container-xxl) / 2));
            left: $left;
        }
        @include media('>=xxl') {
            top: rem(-341);
        }

        .countries {
            .country {
		        transition: fill .7s cubic-bezier(.25, .1, .25, 1) 0s;
            }
        }
        .city {
            cursor: pointer;
            z-index: 1;
            position: relative;
		    transition: fill .7s cubic-bezier(.25, .1, .25, 1) 0s, stroke .7s cubic-bezier(.25, .1, .25, 1) 0s;

            .border {
                opacity: 0;
            }

            &.active {
                .border {
                    opacity: 1;
                }
            }
        }
        .st1 {
		    transition: fill .7s cubic-bezier(.25, .1, .25, 1) 0s, stroke .7s cubic-bezier(.25, .1, .25, 1) 0s;
        }
    }
    &.style--white {
        .countries {
            .country {
                fill: $color--1;

                &.active {
                    fill: $color--dark;
                }
            }
        }
        .city {
            .border {
                .st3 {
                    stroke: $color--light;
                }
            }
        }
    }
    &.style--black {
        .map {
            .st1 {
                fill: #181818;
            }
        }
        .countries {
            .country {
                fill: #181818;

                &.active {
                    fill: $color--light;
                }
            }
        }
        .city {
            .st2 {
                stroke: $color--light;
            }
            .border {
                .st3, .st4 {
                    stroke: $color--dark;
                }
            }
        }

        &::before {
            background: -moz-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
            background: -webkit-linear-gradient(top, rgba(0, 0, 0, 1) 0%,rgba(0, 0, 0, 0) 100%);
            background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%,rgba(0, 0, 0, 0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 );
        }
    }

    &.opacity--gradient {
        &::before {
            opacity: 0;
        }

        .location-map__map {
            &::before, &::after {
                opacity: 0;
            }
        }
    }
}
