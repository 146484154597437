@import '__settings/init';

.projects-grid {
    padding-bottom: rem(81);

    .intro {

        @include media('<=md') {
            padding-bottom: rem(11);
        }

        &__col {

            @include media('>=md') {
                width: 44%;
            }

            &--desc {
                @include media('>=md') {
                    margin-top: rem(15);

                    max-width: rem(245);
                }
            }
        }
        &__caption {
            margin-bottom: rem(43);

            @include media('>=sm') {
                margin-bottom: rem(86);
            }
        }
    }

    &__row {
        display: flex;
        flex-wrap: wrap;

        gap: rem(20) rem(30);

        @include media('>=sm') {
            gap: rem(30) rem(40);
        }

        @include media('>=lg') {
            gap: rem(43) rem(85);
        }
    }

    &__item {
        width: 100%;
        display: flex;

        @include media('>=sm') {
            width: calc(50% - 20px);
        }

        @include media('>=lg') {
            width: calc(33.3333% - 56.7px);
        }

        &-link {
            display: flex;
            align-items: flex-end;
            gap: rem(22);

            transition: all 0.3s ease-in-out;

            width: 100%;

            position: relative;

            @include media('>=sm') {
                flex-direction: column;
                align-items: flex-start;
                gap: 0;
            }

            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: rem(-1);
                bottom: 0;
                right: 0;
                z-index: 1;
                opacity: 0;
                background-color: rgba(255, 255, 255, 0.5);
                -webkit-backdrop-filter: grayscale(1);
                backdrop-filter: grayscale(1);
                transition: all 0.3s ease-in-out;
                pointer-events: none;
            }

            &--blur {
                &::after {
                    opacity: 1;
                    transition: all 0.3s ease-in-out;
                }
            }

            &:hover {
                .projects-grid__item-title {
                    &::after {
                        width: 100%;
                        transition: width 0.3s ease-in-out;
                    }

                    &__icon {
                        transform: translateX(0);
                        transition: all 0.2s ease-in-out;
                    }
                }
            }
        }

        &-image {
            width: 100%;

            position: relative;

            &::after {
                content: '';
                display: block;

                padding-bottom: 40%;

                @include media('>=450px') {
                    padding-bottom: 50.15772870662461%;
                }
            }

            img {
                position: absolute;
                left: 0;
                right: 0;

                width: 100%;
                height: 100%;

                object-fit: cover;
            }
        }

        &-title {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            gap: rem(10);

            width: 120%;

            position: relative;

            margin-bottom: rem(10);

            @include theinhardt-regular;
            @include font(18, 20);

            @include media('>=sm') {
                display: inline-block;
                width: auto;

                padding-top: rem(9);
                margin-left: rem(-2);
                margin-bottom: 0;

                @include font(24, 26);
            }

            &::before,
            &::after {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                bottom: rem(-10);
                height: rem(1);
                background-color: currentColor;

                @include media('>=sm') {
                    bottom: rem(-9);
                }
            }

            &::before {
                width: 100%;
                opacity: 0.2;
                z-index: 1;
            }

            &::after {
                width: 0;
                z-index: 2;
                transition: width 0.3s ease-in-out;
            }

            &__icon {
                display: flex;

                margin-bottom: rem(4);

                transform: translateX(-2px);
                transition: all 0.2s ease-in-out;

                @include media('>=sm') {
                    display: none;
                }
                svg {
                    min-width: rem(10);
                    width: rem(10);
                    height: rem(10);
                }
            }
        }
    }

    &.style--black {
        .projects-grid__item-link::after {
            background-color: rgba(0, 0, 0, 0.5);
        }
    }
}
