@import "__settings/init";

.large-title {
	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;

		&-small {
			z-index: 2;
		}

		&-small, &-big {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
		}

		&-big:after {
			content: '';
			display: block;
			width: 100%;
			height: 58%;
			position: absolute;
			bottom: 0;
			left: 0;
			// background: linear-gradient(180deg, rgba(244, 244, 244, 0) 0%, #ffffff 59.33%);
		}

		svg {
			display: block;
			margin: 0 auto;
		}
	}
}
.style--black {
	.large-title {
		&__bg {
			&-small {
				svg {
					filter: invert(1);
				}
			}
			&-big {
				:after {
					background: linear-gradient(180deg, rgba(244, 244, 244, 0) 0%, #000000 59.33%);
				}

				svg path {
					fill: rgb(11, 11, 11);
				}
			}
		}
	}
}

.style--white {
	.large-title {
		&__bg {
			&-small {
				svg {
					filter: invert(0);
				}
			}
			&-big {
				&:after {
					background: linear-gradient(180deg, rgba(244, 244, 244, 0) 0%, #ffffff 59.33%);
				}

				svg path {
					fill: #F4F4F4;
				}
			}
		}
	}
}