.main-header {
	display: flex;
	justify-content: center;

	width: 100%;
	padding: 0 rem(31);
	min-height: rem(60);

	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;

	color: $color--dark;
	background-color: $color--light;
	transform: translateY(0);
	transition: transform $default-transition-speed ease-in-out, background-color $default-transition-speed ease-in-out;

	@include media('>=lg') {
		min-height: rem(108);
	}

	&.hidden, &.popup-on {
		transform: translateY(-100%);

		.main-header__mobile {
			height: 100vh;
		}
	}
	// &.menu-open.hidden {
	// 	transition: transform 0s ease-in-out;
	// }

	&.menu-open {
		transition: transform 0s ease-in-out;
		transform: translateY(var(--transform));
		.main-header__logo {
			svg {
				&:first-of-type {
					display: none;
				}
				&:last-child {
					display: inline-block;
				}
			}
		}
	}

	&__inner {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		max-width: rem(1282);
	}

	&__logo {
		flex: 0 0 auto;

		max-width: rem(125);
		position: relative;

		.main-logo {
			display: flex;
		}

		svg {
			fill: $color--dark;
			transition: fill $default-transition-speed ease-in-out;

			&:first-of-type {
				display: inline-block;
			}
			&:last-child {
				display: none;
				max-width: rem(18);
			}
		}
	}
	&__nav {
		@include media('<lg') {
			display: none;
		}
		@include media('>=lg') {
			display: block;
			flex: 0 0 auto;

			max-width: calc(100% - 100px);
		}
	}
	&__desktop-nav {
		display: flex;
		align-items: center;
		gap: rem(60);

		.menu {
			display: flex;
			gap: rem(47);
		}

		@include media('>=xl') {
			gap: rem(106);
		}
	}
	&__desktop-nav,
	&__mobile-nav {
		padding: 0;

		z-index: 9;

		@include font(16, 22.4);

	}

	&__mobile {
		height: calc(var(--height) - 60px);
		overflow-y: auto;

		@include media('<lg') {
			padding: rem(61) rem(32) rem(42);
			width: 100%;

			position: absolute;
			top: 100%;
			left: 0;

			background: $color--light;

			display: flex;
			flex-direction: column;

			transition: all 0.3s ease-in-out;
			transform: translateX(0);
		}

		&[hidden] {
			transform: translateX(-101%);
		}

		@include media('>=lg') {
			display: none;
		}

		.account-menu {
			display: flex;
			align-items: center;
			gap: rem(9);
			margin-bottom: rem(76);
			.menu-item-language {
				opacity: 0.4;
				@include font(14, 20);
			}
			.menu-item-language-current {
				position: relative;
				opacity: 1;

				&::after {
					content: "";
					display: block;
					position: absolute;
					transition: width 0.3s cubic-bezier(0.25, 0.1, 0.25, 1) 0s;
					right: 0;
					left: 0;
					width: 100%;
					bottom: rem(-2);
					height: rem(1);
					background-color: currentColor;
				}

			}
		}

		.menu {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: rem(14.5);
			margin-bottom: rem(126);

			.menu-item {
				@include font(30, 30);
			}

			.menu-item-has-children {
				&::after {
					display: none;
				}

			}

			.menu-item__submenu {
				margin-left: rem(22);

				.menu-item {
					@include font(20, 20);
					opacity: 0.6;
					transition: opacity 0.2s ease-in-out;

					&:hover {
						opacity: 1;
						transition: opacity 0.2s ease-in-out;
					}
				}
			}
		}

		&__bottom {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-top: auto;

			.menu {
				padding: 0;
				list-style: none;
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: rem(21);
				margin-bottom: rem(12);

				.menu-item {
					@include font(14, 15);

					&::after {
						display: none;
					}

					a {
						text-decoration: underline;
						opacity: 0.5;
						transition: opacity 0.2s ease-in-out;

						&:hover {
							opacity: 1;
							transition: opacity 0.2s ease-in-out;
						}
					}
				}
			}
		}
	}

	&__copyright {
		order: 2;
		@include font(12, 13);
		opacity: 0.5;

	}

	&__social-media {
		display: flex;
		align-items: center;
		gap: rem(26);
		margin-bottom: rem(16);

		&-item {
			display: flex;
			align-items: center;
			justify-content: center;
			opacity: 0.5;
			transition: opacity 0.2s ease-in-out;
			width: rem(17);
			height: rem(17);

			&:hover {
				opacity: 1;
				transition: opacity 0.2s ease-in-out;
			}

			svg {
				width: rem(17);
				height: rem(17);

				path {
					fill: currentColor;
				}
			}
		}
	}

	.menu-item {
		display: block;

		@include font(16, 20);

		position: relative;
		margin: 0;

		&.hidden {
			display: none !important;
		}

		@include media('>=lg') {
			display: inline-block;
		}

		&::after {
			content: '';
			display: block;
			position: absolute;
			transition: width .3s cubic-bezier(.25,.1,.25,1) 0s;
			right: 0;
			bottom: rem(-3);
			height: rem(1);
			background-color: currentColor;
			width: 0;

			@include media('>=lg') {
				bottom: rem(-9);
			}
		}

		&:hover {
			&::after {
				left: 0;
				right: auto;
				width: 100%;
			}
		}

		&.menu-item-has-children {
			&::before {
				@include media('>=lg') {
					content: '';
					display: block;
					position: fixed;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					height: 100vh;
					background-color: $color--light;
					opacity: 0;
					z-index: -1;
					transition: opacity .2s linear;
					pointer-events: none;
				}
			}

			&:hover {
				@include media('>=lg') {
					z-index: 8;
				}

				&::before {
					@include media('>=lg') {
						opacity: 0.7;
						transition: opacity .2s linear;
					}
				}
			}
		}

		&.current-menu-item {
			&::after {
				left: 0;
				right: 0;
				width: 100%;
			}

			& > a {
				@include theinhardt-medium();
			}
		}

		> a {
			vertical-align: middle;

			color: currentColor;

			@include theinhardt-light();
			transition: color $default-transition-speed;

			&:not([href]) {
				user-select: none;
			}

		}
		& + & {
			@include media('>=lg') {
				margin-left: rem(32);
			}
		}
		&__dropdown {
			width: rem(16);
			height: rem(16);
			padding: 0;

			position: relative;
			margin: 0 0 0 rem(9);
			vertical-align: middle;

			border: 0;
			border-radius: 50%;
			background: transparent;

			color: inherit;

			cursor: auto;

			@include media('>=lg') {
				margin: 0 0 0 rem(6);
				width: rem(11);
				height: rem(13);
			}
		}
		&__icon {
			display: flex;
			align-items: center;

			svg {
				width: rem(16);
				height: rem(16);
				fill: currentColor;
				transition: fill $default-transition-speed ease-in-out;

				@include media('>=lg') {
					width: rem(11);
					height: rem(11);
				}
			}
		}
		&__submenu {
			&:not([hidden]) {
				display: flex;
			}
			flex-direction: column;
			align-items: flex-start;
			gap: rem(12);

			@include media('<lg') {
				padding: rem(20) 0;
			}
			@include media('>=lg') {
				width: auto;
				min-width: rem(150);
				position: absolute;
				top: 100%;
				padding-top: rem(30);
			}
			.menu-item {
				display: flex;
				padding: 0;

				&::after {
					bottom: rem(-3);
				}

				&:last-child {
					margin: 0;
				}
			}
		}
	}

	&--black {
		color: $color--light;
		background-color: $color--dark;
		.main-header__logo svg {
			fill: $color--light;
		}
		.menu-item {
			&.menu-item-has-children {
				&::before {
					background-color: $color--dark;
				}
			}
		}
		.main-header__mobile {
			background-color: $color--dark;
		}

		::selection {
			color: $color--dark;
			background: $color--light;
		}
		::-moz-selection {
			color: $color--dark;
			background: $color--light;
		}
	}

	&--white {
		::selection {
			color: $color--light;
			background: $color--dark;
		}
		::-moz-selection {
			color: $color--light;
			background: $color--dark;
		}
	}
}

