@import '__settings/init';

.main-footer {
	// background-color: $color--dark;
	// color: $color--light;
	padding: rem(65) 0 rem(35);

	position: relative;

	::selection {
		color: $color--dark;
		background: $color--light;
	}
	::-moz-selection {
		color: $color--dark;
		background: $color--light;
	}

	a:focus {
		color: currentColor;
	}

	&__wrap {
		display: flex;
		flex-direction: column;
		gap: rem(31);
	}

	&__logo {
		width: rem(20);
		height: rem(21);
		display: flex;
		svg {
			fill: $color--light;
			width: 100%;
			height: auto;
		}
	}

	&__top {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
		padding-bottom: rem(79);

		&-menus {
			display: flex;
			flex-wrap: wrap;
			gap: rem(32) rem(56);
		}
		&-menu {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: rem(1);

			h3 {
				margin-bottom: 0;
				@include font(16, 24);
				@include theinhardt-medium();
			}

			ul {
				display: flex;
				flex-direction: column;
				list-style: none;
				gap: rem(5);
				padding: 0;
				margin: 0;

				a {
					position: relative;

					@include font(14, 12);
					@include theinhardt-light();

					opacity: 0.6;

					transition: all 0.3s ease-in-out;


					&:hover {
						opacity: 1;

						transition: all 0.3s ease-in-out;

						&::after {
							left: 0;
							right: auto;
							width: 100%;
						}
					}

					&::after {
						content: "";
						display: block;
						position: absolute;
						transition: width 0.3s cubic-bezier(0.25, 0.1, 0.25, 1) 0s;
						right: 0;
						bottom: 0;
						height: rem(1);
						background-color: currentColor;
						width: 0;
					}
				}
			}

			&:last-child {
				min-width: rem(126);
			}
		}
	}
	&__newsletter {
		margin-bottom: rem(68);
		width: 100%;
	}

	&__divider {
		width: 100%;
		height: rem(1);
		opacity: 0.4;
		margin-right: rem(0);
		margin-left: rem(0);
		margin-top: rem(36);
		margin-bottom: rem(40);
		background-color: $color--light;
	}

	&__download {
		display: flex;
		flex-direction: column;
		width: 100%;

		&-link {
			display: flex;
			flex-direction: row;
			gap: rem(27);

			margin: 0;
			padding: 0;

			background-color: transparent;
			border: none;
			color: currentColor;

			cursor: pointer;

			&:hover {
				svg {
					transform: translateX(3px);

					opacity: 1;

					transition: all 0.3s ease-in-out;
				}
			}
		}

		&-image {
			position: relative;
			&::before {
				content: '';
				display: block;
				position: absolute;
				background: linear-gradient(90deg, rgba(0, 0, 0, 0.17) 0%, rgba(0, 0, 0, 0) 28.29%);
				width: 100%;
				height: 100%;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
			}
			img {
				width: rem(104);
				height: auto;
				box-shadow: rem(0) rem(4) rem(5) rem(2) rgba(81, 81, 81, 0.01);
			}
		}

		&-text {
			max-width: rem(129);
			align-self: flex-end;
			@include font(16, 20.8);
			text-align: left;
			display: inline;
			margin-bottom: rem(-8);

			svg {
				width: rem(13);
				height: rem(13);
				opacity: 0.5;
				margin-left: rem(2);
				margin-bottom: rem(3);

				transition: all 0.3s ease-in-out;
			}

		}
	}

	&__bottom {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;

		.menu {
			padding: 0;
			margin: 0;
			list-style: none;
			display: flex;
			align-items: center;
			gap: rem(21);
			margin-bottom: rem(8);

			li {
				a {
					opacity: 0.5;
					@include font(14, 15.4);
					@include theinhardt-light();
					text-decoration: underline;
					transition: opacity 0.3s ease-in-out;

					&:hover {
						opacity: 1;
						transition: opacity 0.3s ease-in-out;
					}
				}
			}
		}
	}

	&__copyright {
		@include font(12, 13.2);
		@include theinhardt-light();
		opacity: 0.5;
		order: 2;

		p {
			margin-top: 0;
		}

	}

	&__social-media {
		display: flex;
		align-items: center;
		gap: rem(26);
		margin-bottom: rem(12);

		&-item {
			display: flex;
			align-items: center;
			justify-content: center;
			opacity: 0.5;
			transition: opacity 0.3s ease-in-out;
			width: rem(17);
			height: rem(17);

			svg {
				width: rem(17);
				height: rem(17);
			}

			&:hover {
				opacity: 1;
				transition: opacity 0.3s ease-in-out;
			}
		}
	}

	@include media('>=lg') {
		padding: rem(67) 0 rem(62);

		&__logo {
			width: rem(30);
			height: rem(31);
		}

		&__top {
			flex-direction: row;
			padding-bottom: rem(107);
			&-menus {
				gap: rem(31) rem(44);
				margin-top: rem(4);
			}
			&-menu {
				gap: rem(13);
				max-width: rem(126);
			}
		}
		&__newsletter {
			max-width: rem(359);
			margin-right: rem(40);
			margin-bottom: rem(0);
		}
		&__divider {
			width: rem(1);
			height: auto;
			margin-right: rem(78);
			margin-left: rem(76);
			margin-top: 0;
			margin-bottom: 0;
		}
		&__download {
			margin-top: rem(3);
			flex-direction: row;
			width: auto;

			&-link {
				flex-direction: column;
				gap: rem(18);
				min-width: rem(158);
			}
			&-image {
				padding-left: rem(2);
				img {
					width: rem(145);
				}
			}
			&-text {
				max-width: rem(158);
				align-self: flex-start;
				@include font(14, 19.6);
				margin-bottom: rem(0);
				svg {
					width: rem(9);
					height: rem(9);
					margin-left: rem(3);
					margin-bottom: rem(4);
				}
			}
		}
		&__bottom {
			flex-direction: row;
			gap: rem(15);

			.menu {
				gap: rem(22);
				margin-bottom: rem(0);
			}
		}
		&__copyright {
			@include font(14, 15.4);
			order: 0;
		}
		&__social-media {
			margin-left: rem(-72);
			margin-top: rem(3);
			margin-bottom: rem(0);
			gap: rem(33);
			&-item {
				width: rem(14);
				height: rem(14);

				svg {
					width: rem(14);
					height: rem(14);
				}
			}
		}
	}

	@include media('>=xl') {
		&__newsletter {
			margin-right: rem(162);
		}
		&__top {
			&-menus {
				gap: rem(5) rem(44);
			}
			&-menu {
				&:last-child {
					margin-left: auto;
				  }
			}
		}
	}

}
