//
// MIXINS
//
@mixin cf {
	&:before,
	&:after {
		content: '';

		display: table;
	}

	&:after {
		clear: both;
	}
}

@function vw-size($s, $w: 1440) {
	@return ($s / $w) * 100 + vw;
}

@function rem($sizeValue: 16) {
	@return calc($sizeValue / 16) + rem;
}

@function rel($size, $rel) {
	@return calc($size / $rel);
}

@function em($size, $rel) {
	@return rel($size, $rel) * 1em;
}

@mixin object-fit-cover() {
	width: 100%;
	height: 100%;

	position: absolute;
	top: 0;
	left: 0;

	object-fit: cover;
}

@mixin line-height($font-size, $line-height: 0) {
	@if $line-height != 0 {
		@if $line-height < 10 {
			line-height: $line-height;
		}
		@else {
			line-height: calc($line-height / $font-size);
		}
	}
}

@mixin letter-spacing($font-size, $letter-spacing: 0) {
	@if $letter-spacing != 0 {
		@if $letter-spacing < 10 {
			letter-spacing: rem($letter-spacing);
		}
		@else {
			letter-spacing: rem(($font-size * $letter-spacing) / 1000);
		}
	}
}

@mixin font($font-size-map, $line-height: 0, $letter-spacing: 0) {
	@if type-of($font-size-map) == 'map' {
		@each $breakpoint, $font-size in $font-size-map {
			@if $breakpoint == null {
				font-size: rem($font-size);

				@include line-height(map-get($font-size-map, nth(map-keys($font-size-map), -1)), $line-height);
				@include letter-spacing(map-get($font-size-map, nth(map-keys($font-size-map), -1)), $letter-spacing);
			}
			@else {
				@media screen and (min-width: map-get($breakpoints, $breakpoint)) {
					font-size: rem($font-size);
				}
			}
		}
	}
	@else {
		font-size: rem($font-size-map);

		@include line-height($font-size-map, $line-height);
		@include letter-spacing($font-size-map, $letter-spacing);
	}
}

@mixin scrollbar-hidden() {
	scrollbar-width: none;

	&::-webkit-scrollbar {
		width: 0;
		display: none;
	}
}

// Fonts

@mixin noe-display-thin {
	font-family: 'Noe Display';
	font-weight: 100;
    font-style: normal;
}

@mixin noe-display-regular {
	font-family: 'Noe Display';
    font-weight: normal;
    font-style: normal;
}

@mixin noe-display-regular-italic {
	font-family: 'Noe Display';
    font-weight: normal;
    font-style: italic;
}

@mixin noe-display-medium {
	font-family: 'Noe Display';
    font-weight: 500;
    font-style: normal;
}

@mixin noe-display-medium-italic {
	font-family: 'Noe Display';
    font-weight: 500;
    font-style: italic;
}

@mixin noe-display-bold {
	font-family: 'Noe Display';
    font-weight: bold;
    font-style: normal;
}

@mixin noe-display-bold-italic {
	font-family: 'Noe Display';
    font-weight: bold;
    font-style: italic;
}

@mixin theinhardt-thin {
	font-family: 'Theinhardt';
    font-weight: 100;
    font-style: normal;
}

@mixin theinhardt-thin-italic {
	font-family: 'Theinhardt';
    font-weight: 100;
    font-style: italic;
}

@mixin theinhardt-ultra-light {
	font-family: 'Theinhardt';
    font-weight: 200;
    font-style: normal;
}

@mixin theinhardt-ultra-light-italic {
	font-family: 'Theinhardt';
    font-weight: 200;
    font-style: italic;
}

@mixin theinhardt-hairline {
	font-family: 'Theinhardt Hairline';
    font-weight: 300;
    font-style: normal;
}

@mixin theinhardt-hairline-italic {
	font-family: 'Theinhardt Hairline';
    font-weight: 300;
    font-style: italic;
}

@mixin theinhardt-light {
	font-family: 'Theinhardt';
    font-weight: 300;
    font-style: normal;
}

@mixin theinhardt-light-italic {
	font-family: 'Theinhardt';
    font-weight: 300;
    font-style: italic;
}

@mixin theinhardt-regular {
	font-family: 'Theinhardt';
    font-weight: normal;
    font-style: normal;
}

@mixin theinhardt-regular-italic {
	font-family: 'Theinhardt';
    font-weight: normal;
    font-style: italic;
}

@mixin theinhardt-medium {
	font-family: 'Theinhardt';
    font-weight: 500;
    font-style: normal;
}

@mixin theinhardt-medium-italic {
	font-family: 'Theinhardt';
    font-weight: 500;
    font-style: italic;
}

@mixin theinhardt-bold {
	font-family: 'Theinhardt';
    font-weight: bold;
    font-style: normal;
}

@mixin theinhardt-bold-italic {
	font-family: 'Theinhardt';
    font-weight: bold;
    font-style: italic;
}